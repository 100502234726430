import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import CtaSecondary from "../Buttons/secondary-cta";
import Image from "../image";
import PosSectionStyles from "./PosSectionStyles";

const { Title } = Typography;

const PosSection = (props) => {
  const { sectionData } = props;

  return (
    <div className="pos-section">
      {sectionData.reverseOrder && (
        <div>
          <div className="pos-section__content">
            <Image
              imageName={sectionData.image}
              style={sectionData.assetStyles}
              alt={sectionData.alt}
            />
          </div>
          <p className="pos-section__fat-title">{sectionData.fatText}</p>
        </div>
      )}
      <Row className="pos-section__text">
        <Col span={24}>
          <div className="pos-section__text">
            <Title level={2}>
              {" "}
              {sectionData.title}
              <span className="blue_dot">.</span>{" "}
            </Title>
            <p>{sectionData.subtext}</p>
            <Row className="pos-section__cta">
              <CtaSecondary
                target={sectionData?.SecondaryCta?.ctaTarget ?? ""}
                ctaTitle={sectionData.SecondaryCta.ctaTitle}
              />
            </Row>
          </div>
        </Col>
      </Row>
      {!sectionData.reverseOrder && (
        <div>
          <div className="pos-section__content">
            {!sectionData.noBgShapes && (
              <div>
                <div className="pos-section__spellout" />
                <div className="blue-mound" />
              </div>
            )}
            <Image
              imageName={sectionData.image}
              style={sectionData.assetStyles}
            />
          </div>
          <p className="pos-section__fat-title">{sectionData.fatText}</p>
        </div>
      )}

      <PosSectionStyles />
    </div>
  );
};

PosSection.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
PosSection.defaultProps = {
  sectionData: {
    SecondaryCta: {
      ctaTarget: "",
    },
  },
};

export default PosSection;
