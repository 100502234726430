import React from 'react';
import { viewports, colors } from '../../style-vars';
import bgImage from '../../images/global_assets/restaurant-spellout.png';

const { xsOnly, mdOrSmaller, lgOrSmaller } = viewports;
const { primary } = colors;

const PosSectionStyles = () => (
  <style jsx global>{`
    .pos-section {
      position: relative;
    }
    .pos-section__text {
      max-width: 700px;
      margin: auto;
    }
    .pos-section__text h2,
    .pos-section__text p {
      text-align: center;
    }
    .pos-section__text p {
      color: #748093;
      font-size: 16px !important;
      line-height: 1.7;
    }
    @media ${mdOrSmaller} {
      .pos-section__text {
        margin: auto;
        padding: 0 16px;
      }
      .pos-section__text h2,
      .pos-section__text p {
        text-align: left !important;
      }
    }
    @media ${xsOnly} {
      .pos-section {
        margin-top: -130px;
        padding-top: 40px;
        background: ${colors.white};
      }
    }
    .pos-section__cta {
      justify-content: center;
    }
    @media ${mdOrSmaller} {
      .pos-section__cta {
        justify-content: start;
      }
    }
    .pos-section__content {
      width: 75%;
      margin: 0 auto;
      margin-top: 70px !important;
      position: relative;
    }
    @media ${mdOrSmaller} {
      .pos-section__content {
        width: 100%;
        margin-top: 40px !important;
      }
    }
    .pos-section__fat-title {
      opacity: 0.05;
      position: absolute;
      z-index: -999;
      font-weight: 900;
      font-size: 260px;
      bottom: -40%;
    }
    @media ${lgOrSmaller} {
      .pos-section__fat-title {
        font-size: calc((75vw - 8rem) / 3.55);
      }
    }
    @media ${mdOrSmaller} {
      .pos-section__fat-title {
        display: none;
      }
    }
    .pos-section__spellout {
      position: absolute;
      top: 56%;
      left: -20%;
      width: 135%;
      height: 222px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${bgImage});
    }
    @media ${mdOrSmaller} {
      .pos-section__spellout {
        display: none;
      }
    }
    .blue-mound {
      position: absolute;
      top: -5%;
      width: 100%;
      max-width: 60%;
      height: 100%;
      background-color: ${primary};
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      left: 47%;
      transform: translateX(-50%);
    }
  `}</style>
);

export default PosSectionStyles;
